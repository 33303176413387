/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, lazy, Suspense } from 'react'
import { Button, FlexBox, Icon, InfoSwal, Text } from 'vspry-style-components'
import {
    Route,
    Routes,
    useSearchParams,
    Navigate,
    RouterProvider,
    createHashRouter,
    createRoutesFromElements,
    useRouteError,
    useNavigate,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useSSO } from 'context/ssoContext'
import { useAuth } from 'context/authContext'
import { useAppConfig } from 'context/appConfigContext'

import NavDrawer from 'components/navigation/NavDrawer'
import PrivateRoute from 'components/navigation/PrivateRoute'

/**
 * Import pages
 */
import { isConsumer } from 'utils/config'
import useHashSearchParams from 'hooks/hashSearchParams'
import SemiPrivateRoute from 'components/navigation/SemiPrivateRoute'
import FetchingPage from './public/FetchingPage'
import LandingPage from './public/LandingPage'
import TermsAndConditionsPage from './user/TermsAndConditionsPage'
import VerificationPage from './user/VerificationPage'
import ErrorPage from './public/ErrorPage'

const SignIn = lazy(() => import('./public/SignInPage'))
const UserSignUp = lazy(() => import('./public/UserSignupPage'))
const IdentityFormPage = lazy(() => import('./public/IdentityFormPage'))
const PageNotFoundPage = lazy(() => import('./public/PageNotFoundPage'))
const RecoveryPage = lazy(() => import('./public/RecoveryPage'))
const ResetPasswordPage = lazy(() => import('./user/ResetPasswordPage'))
const ProductHome = lazy(() => import('./user/ProductHome'))
const DirectCredit = lazy(() => import('./user/payments/DirectCredit'))
const DirectDebit = lazy(() => import('./user/payments/DirectDebit'))
const DirectDebitHistoryPage = lazy(() => import('./user/payments/DirectDebitHistoryPage'))
const ProfilePage = lazy(() => import('./user/ProfilePage'))
const ContactUsPage = lazy(() => import('./user/ContactUsPage'))
const CreateBillPage = lazy(() => import('./merchant/CreateBillPage'))
const AddProductPage = lazy(() => import('./user/AddProductPage'))
const ConsumerLoanApplicationFormPage = lazy(() => import('./user/loans/ApplicationFormPage'))
const PaymentPlanPage = lazy(() => import('./user/payments/PaymentPlanPage'))
const PaymentPlansPage = lazy(() => import('./user/payments/PaymentPlansPage'))
const ReimbursementPage = lazy(() => import('./user/payments/ReimbursementPage'))
const MessagePage = lazy(() => import('./user/inbox/MessagePage'))
const InboxPage = lazy(() => import('./user/inbox/InboxPage'))
const UploadDocumentPage = lazy(() => import('./user/documents/UploadDocumentPage'))
const PayoutApplicationPage = lazy(() => import('./user/loans/PayoutApplicationPage'))
const PayoutPage = lazy(() => import('./user/loans/PayoutPage'))
const SignContractPage = lazy(() => import('./user/documents/SignContractPage'))
const OrdersPage = lazy(() => import('./user/marketplace/OrdersPage'))
const OrderPage = lazy(() => import('./user/marketplace/OrderPage'))
const QuestionnairePage = lazy(() => import('./user/marketplace/QuestionnairePage'))
const ImageUploadPage = lazy(() => import('./user/marketplace/ImageUploadPage'))
const ResultsPage = lazy(() => import('./user/marketplace/ResultsPage'))
const DocumentVault = lazy(() => import('./user/documents/DocumentVault'))
const RequestLOAPage = lazy(() => import('./user/policies/RequestLOAPage'))
const LinkPolicyPage = lazy(() => import('./user/policies/LinkPolicyPage'))
const ActivitiesPage = lazy(() => import('./user/policies/ActivitiesPage'))
const DoctorSearchPage = lazy(() => import('./user/policies/DoctorSearchPage'))
const ProviderSearchPage = lazy(() => import('./user/policies/ProviderSearchPage'))
const CustomerInfoPage = lazy(() => import('./user/policies/CustomerInfoPage'))
const PlanPage = lazy(() => import('./user/policies/PlanPage'))
const ReferralPage = lazy(() => import('./public/ReferralPage'))
const RewardsSetupPage = lazy(() => import('./merchant/RewardsSetupPage'))
const RewardClaimsPage = lazy(() => import('./user/rewards/RewardClaimsPage'))
const RewardsClaimsPage = lazy(() => import('./merchant/RewardsClaimsPage'))
const RewardsClaimPage = lazy(() => import('./merchant/RewardsClaimPage'))
const RewardsClaimDecisionPage = lazy(() => import('./merchant/RewardsClaimDecisionPage'))
const RewardsRedemptionsPage = lazy(() => import('./merchant/RewardsRedemptionsPage'))
const RewardsRedemptionPage = lazy(() => import('./merchant/RewardsRedemptionPage'))
const RewardsRedemptionDeliveredPage = lazy(() => import('./merchant/RewardsRedemptionDeliveredPage'))
const StatementsPage = lazy(() => import('./user/StatementsPage'))
const InsuranceReferralPage = lazy(() => import('./merchant/InsuranceReferralPage'))
const PublicInsuranceReferralPage = lazy(() => import('./public/PublicInsuranceReferralPage'))
const PlansPage = lazy(() => import('./merchant/PlansPage'))
const CheckinsListPage = lazy(() => import('./merchant/CheckinsListPage'))
const MarketingPage = lazy(() => import('./merchant/MarketingPage'))
const HelpPage = lazy(() => import('./user/HelpPage'))
const HowToVideosPage = lazy(() => import('./user/HowToVideosPage'))
const MerchantLoanApplicationFormPage = lazy(() => import('./merchant/loans/ApplicationFormPage'))
const MerchantLoanApplicationsPage = lazy(() => import('./merchant/loans/ApplicationsPage'))
const MerchantLoanApplicationPage = lazy(() => import('./merchant/loans/ApplicationPage'))
const CampaignPage = lazy(() => import('./merchant/CampaignPage'))
const OfferActivationsPage = lazy(() => import('./user/OfferActivationsPage'))
const MerchantOfferActivationsPage = lazy(() => import('./merchant/OfferActivationsPage'))
const MerchantOfferActivationPage = lazy(() => import('./merchant/OfferActivationPage'))
const BankAccountsPage = lazy(() => import('./merchant/documents/BankAccountsPage'))
const FinancialStatementsPage = lazy(() => import('./merchant/documents/FinancialStatementsPage'))
const RegistrationDocumentsPage = lazy(() => import('./merchant/documents/RegistrationDocumentsPage'))
const OfflineLodgementPage = lazy(() => import('./merchant/loans/OfflineLodgementPage'))
const WhatsNextPage = lazy(() => import('./merchant/loans/WhatsNextPage'))
const RewardsTriggerSetupPage = lazy(() => import('./merchant/RewardsTriggerSetupPage'))
const RewardsGoalSetupPage = lazy(() => import('./merchant/RewardsGoalSetupPage'))
const RewardsEditTermsPage = lazy(() => import('./merchant/RewardsEditTermsPage'))
const RewardsEditCardPage = lazy(() => import('./merchant/RewardsEditCardPage'))
const RewardsCustomersPage = lazy(() => import('./merchant/RewardsCustomersPage'))

function ThrowAnError() {
    return (
        <Button
            id='error-thrower-button'
            onClick={() => {
                throw new Error('this is an error')
            }}
        >
            {/* eslint-disable i18next/no-literal-string */}
            Throw?
        </Button>
    )
}

function Callback() {
    const { authCallback } = useSSO()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        authCallback(searchParams)
    }, [])

    return <FetchingPage />
}

function FunctionElement({ func, to }: { func: () => Promise<void>; to?: string }) {
    const navigate = useNavigate()
    useEffect(() => {
        const t = async () => {
            await func()
            if (to) navigate(to)
        }
        t()
    }, [])
    return <FetchingPage />
}

function Redirect({ to }: { to: string }) {
    const [searchParams] = useSearchParams()
    return <Navigate to={{ pathname: to, search: `?${searchParams.toString()}` }} replace />
}

export default function ViewController() {
    const { user, logout, fetching, tsAndCs, account } = useAuth()
    const { appConfig, fetchingConfig } = useAppConfig()

    if (fetching || fetchingConfig) return <FetchingPage />

    if (user && !tsAndCs)
        return <RouterProvider router={createHashRouter(createRoutesFromElements(<Route path='*' element={<TermsAndConditionsPage />} />))} />

    if (
        user &&
        ((!user.emailVerified && appConfig.email_verification_enabled) || (!user.phoneVerified && user.phone && appConfig.phone_verification_enabled))
    )
        return (
            <RouterProvider
                router={createHashRouter([
                    {
                        path: '/referral',
                        Component: ReferralPage,
                    },
                    {
                        path: '*',
                        Component() {
                            const { loading } = useHashSearchParams()

                            if (loading) return <FetchingPage />

                            return <VerificationPage />
                        },
                    },
                ])}
            />
        )

    return (
        <RouterProvider
            router={createHashRouter([
                {
                    path: '*',
                    Component() {
                        const { loading } = useHashSearchParams()

                        if (loading) return <FetchingPage />
                        return (
                            <>
                                {user && <NavDrawer />}
                                <Suspense fallback={<FetchingPage />}>
                                    <Routes>
                                        <Route path='/signout' element={<FunctionElement func={logout} to='/' />} />

                                        {/* Public Pages */}
                                        <Route path='/' element={<LandingPage />} />
                                        <Route path='/signin' element={<SignIn />} />
                                        <Route path='/signup' element={<UserSignUp />} />
                                        <Route path='/identity' element={<IdentityFormPage />} />
                                        <Route path='/recovery' element={<RecoveryPage />} />
                                        <Route path='/referral' element={<ReferralPage />} />
                                        {appConfig.health_cover_referral_enabled && (
                                            <Route path='/healthcoverreferral' element={<PublicInsuranceReferralPage />} />
                                        )}
                                        <Route path='/help' element={<HelpPage />} />
                                        <Route path='/howto' element={<HowToVideosPage />} />
                                        <Route path='/auth/callback' element={<Callback />} />
                                        <Route path='/error' element={<ErrorPage />} />

                                        {/* Semi Protected Pages */}
                                        <Route element={<SemiPrivateRoute />}>
                                            <Route path='/profile'>
                                                <Route path='password' element={<ResetPasswordPage />} />
                                            </Route>
                                        </Route>

                                        {/* Protected Pages */}
                                        <Route element={<PrivateRoute />}>
                                            <Route path='/home' element={<ProductHome />} />

                                            {/* Configurable Pages */}
                                            {appConfig && (
                                                <>
                                                    {appConfig.direct_debit_enabled && (
                                                        <>
                                                            <Route path='/directdebit' element={<DirectDebit />}>
                                                                <Route path='history' element={<DirectDebitHistoryPage />} />
                                                            </Route>
                                                            <Route path='/paymentplans'>
                                                                <Route path=':mandateID' element={<PaymentPlanPage />} />
                                                                <Route index element={<PaymentPlansPage />} />
                                                            </Route>
                                                        </>
                                                    )}
                                                    {appConfig.vcode_enabled && <Route path='/createbill' element={<CreateBillPage />} />}
                                                    {appConfig.reimbursement_enabled && (
                                                        <Route path='/reimbursementapplication' element={<ReimbursementPage />} />
                                                    )}
                                                    {appConfig.addProduct_enabled && <Route path='/addproduct' element={<AddProductPage />} />}
                                                    {appConfig.merchant_subscriptions_enabled && <Route path='/plans' element={<PlansPage />} />}
                                                    {appConfig.health_cover_referral_enabled && (
                                                        <Route path='/healthreferral' element={<InsuranceReferralPage />} />
                                                    )}
                                                    {appConfig.contact_us_menu_enabled && <Route path='/contact' element={<ContactUsPage />} />}
                                                    {(appConfig.business_loans_enabled || appConfig.assets_enabled.includes('Loan')) && (
                                                        <>
                                                            <Route path='/businessloanapplication' element={<Redirect to='/loans/apply' />} />
                                                            <Route path='/loans'>
                                                                <Route
                                                                    path='apply'
                                                                    element={
                                                                        isConsumer(account) ? (
                                                                            <ConsumerLoanApplicationFormPage />
                                                                        ) : (
                                                                            <MerchantLoanApplicationFormPage />
                                                                            // eslint-disable-next-line react/jsx-indent
                                                                        )
                                                                    }
                                                                />
                                                                <Route
                                                                    path=':loanID'
                                                                    element={
                                                                        isConsumer(account) ? (
                                                                            <Navigate to='/home' />
                                                                        ) : (
                                                                            <MerchantLoanApplicationPage />
                                                                            // eslint-disable-next-line react/jsx-indent
                                                                        )
                                                                    }
                                                                />
                                                                <Route
                                                                    path='offlinelodgement/:eoiID'
                                                                    element={
                                                                        isConsumer(account) ? (
                                                                            <Navigate to='/home' />
                                                                        ) : (
                                                                            <OfflineLodgementPage />
                                                                            // eslint-disable-next-line react/jsx-indent
                                                                        )
                                                                    }
                                                                />
                                                                <Route
                                                                    path='whatsnext'
                                                                    element={
                                                                        isConsumer(account) ? (
                                                                            <Navigate to='/home' />
                                                                        ) : (
                                                                            <WhatsNextPage />
                                                                            // eslint-disable-next-line react/jsx-indent
                                                                        )
                                                                    }
                                                                />
                                                                <Route
                                                                    index
                                                                    element={
                                                                        isConsumer(account) ? (
                                                                            <Navigate to='/home' />
                                                                        ) : (
                                                                            <MerchantLoanApplicationsPage />
                                                                            // eslint-disable-next-line react/jsx-indent
                                                                        )
                                                                    }
                                                                />
                                                            </Route>
                                                        </>
                                                    )}

                                                    {/* Asset-based Configurable Pages */}
                                                    {appConfig.assets_enabled.includes('Loan') && appConfig.loan_payout_enabled && (
                                                        <>
                                                            <Route path='/payoutapplication' element={<PayoutApplicationPage />} />
                                                            <Route path='/payout/:payoutID' element={<PayoutPage />} />
                                                        </>
                                                    )}
                                                    {appConfig.assets_enabled.includes('Policy') && (
                                                        <Route path='/policy'>
                                                            <Route path='requestloa' element={<RequestLOAPage />} />
                                                            {/* <Route path='banktransfer' element={<TransferToBankPage />} /> */}
                                                            <Route path='link' element={<LinkPolicyPage />} />
                                                            <Route path='cif' element={<CustomerInfoPage />} />
                                                            <Route path='activities' element={<ActivitiesPage />} />
                                                            <Route path='doctors' element={<DoctorSearchPage />} />
                                                            <Route path='providers' element={<ProviderSearchPage />} />
                                                            <Route index element={<PlanPage />} />
                                                        </Route>
                                                    )}
                                                    {appConfig.assets_enabled.includes('Reward') && (
                                                        <>
                                                            <Route path='/rewards'>
                                                                <Route path='setup'>
                                                                    <Route path='goals'>
                                                                        <Route path=':triggerID' element={<RewardsTriggerSetupPage />} />
                                                                        <Route index element={<RewardsTriggerSetupPage />} />
                                                                    </Route>
                                                                    <Route path='redemption'>
                                                                        <Route path=':goalID' element={<RewardsGoalSetupPage />} />
                                                                        <Route index element={<RewardsGoalSetupPage />} />
                                                                    </Route>
                                                                    <Route path='terms-and-conditions' element={<RewardsEditTermsPage />} />
                                                                    <Route path='card-branding' element={<RewardsEditCardPage />} />
                                                                    <Route index element={<RewardsSetupPage />} />
                                                                </Route>
                                                                <Route path='claims'>
                                                                    <Route path='decision' element={<RewardsClaimDecisionPage />} />
                                                                    <Route path=':claimID' element={<RewardsClaimPage />} />
                                                                    <Route index element={<RewardsClaimsPage />} />
                                                                </Route>
                                                                <Route path='redemptions'>
                                                                    <Route path=':redemptionID'>
                                                                        <Route path='deliver' element={<RewardsRedemptionDeliveredPage />} />
                                                                        <Route index element={<RewardsRedemptionPage />} />
                                                                    </Route>
                                                                    <Route index element={<RewardsRedemptionsPage />} />
                                                                </Route>
                                                                <Route path='claim' element={<RewardClaimsPage />} />
                                                                <Route path='customers' element={<RewardsCustomersPage />} />
                                                                <Route index element={<Navigate to='/rewards/claims' replace />} />
                                                            </Route>
                                                            <Route path='/checkins'>
                                                                <Route index element={<CheckinsListPage />} />
                                                            </Route>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            <Route path='/statements' element={<StatementsPage />} />
                                            <Route path='/directcredit' element={<DirectCredit />} />
                                            <Route path='/inbox'>
                                                <Route path=':messageID' element={<MessagePage />} />
                                                <Route index element={<InboxPage />} />
                                            </Route>
                                            <Route path='/documents'>
                                                <Route
                                                    index
                                                    element={
                                                        appConfig.document_store_enabled ? <DocumentVault /> : <Navigate to='/documents/upload' />
                                                    }
                                                />
                                                <Route path='upload' element={<UploadDocumentPage />} />
                                                <Route path='sign' element={<SignContractPage />} />
                                                <Route path='bankaccounts' element={<BankAccountsPage />} />
                                                <Route path='financialstatements' element={<FinancialStatementsPage />} />
                                                <Route path='registrationdocuments' element={<RegistrationDocumentsPage />} />
                                            </Route>
                                            <Route path='/marketing'>
                                                <Route path='campaigns'>
                                                    <Route path=':campaignID' element={<CampaignPage />} />
                                                    <Route index element={<Navigate to='/marketing' />} />
                                                </Route>
                                                <Route path='activations'>
                                                    <Route path=':activationID' element={<MerchantOfferActivationPage />} />
                                                    <Route index element={<MerchantOfferActivationsPage />} />
                                                </Route>
                                                <Route index element={<MarketingPage />} />
                                            </Route>
                                            <Route path='/activations' element={<OfferActivationsPage />} />
                                            <Route path='/profile'>
                                                <Route path='password' element={<ResetPasswordPage />} />
                                                <Route index element={<ProfilePage />} />
                                            </Route>
                                            <Route path='/throwanerror' element={<ThrowAnError />} />

                                            {/* Venus Pages */}
                                            {window.configuration['VENUS_URL'] && (
                                                <>
                                                    <Route path='/marketplace'>
                                                        <Route path='questionnaire' element={<QuestionnairePage />} />
                                                        <Route path='images' element={<ImageUploadPage />} />
                                                        <Route path='scans' element={<ResultsPage />} />
                                                    </Route>
                                                    <Route path='/orders'>
                                                        <Route index element={<OrdersPage />} />
                                                        <Route path=':orderID' element={<OrderPage />} />
                                                    </Route>
                                                </>
                                            )}
                                        </Route>
                                        <Route path='/*' element={<PageNotFoundPage />} />
                                    </Routes>
                                </Suspense>
                            </>
                        )
                    },
                    ErrorBoundary() {
                        const e = useRouteError()
                        if (e instanceof Error && e.message.includes('Failed to fetch dynamically imported module'))
                            InfoSwal.fire({
                                title: 'App Update',
                                text: 'Looks like there is a new version of this page! Would you like to refresh to load the new version?',
                            }).then((r) => r.value && window.location.reload())
                        else if (window.configuration['SENTRY_DSN']) Sentry.captureException(e)

                        return (
                            <FlexBox height='100vh' width='100vw' justify='center' $align $column gap='small'>
                                <Icon name='bug' color='error' size='huge' />
                                <Text size='small' margin='no'>
                                    Looks like you&apos;ve found an error!
                                </Text>
                                <Text size='xSmall' margin='no'>
                                    Don&apos;t stress! We have already notified our team.
                                    <br />
                                    In the mean time, try refreshing the page.
                                    <br />
                                    If that doesn&apos;t work, you can try again later.
                                    <br />
                                    Hopefully we will have fixed it - you are our top priority after all!
                                </Text>
                                <FlexBox gap='small' width='300px'>
                                    <Button
                                        $fitted
                                        id='error-boundary-reload-button'
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    >
                                        Reload
                                    </Button>
                                    {user && (
                                        <Button $fitted id='error-boundary-signout-button' onClick={() => logout()}>
                                            Sign Out
                                        </Button>
                                    )}
                                </FlexBox>
                            </FlexBox>
                        )
                    },
                },
            ])}
        />
    )
}
