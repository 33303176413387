/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Address } from 'utils/formatters'
import { plutoFormMutation, plutoMutation, plutoQuery, plutoQueryQuiet, stringify } from './gqlInterface'
import { Activity, Goal } from './merchant'
import { mutationResponseString, PaginatedResponse, Pagination, paginationString } from './types'

export type Store = {
    id: string
    name: string
    phone?: string
    bookingURL?: string
    address: Address
}

export type Offer = {
    id: string
    campaignID: string
    title: string
    description: string
    expiryDate: string
    startDate?: string
    activationLastDate?: string
    activationLastDatePeriod?: number
    stores: Store[]
    bookingURL?: string
    maxActivations?: number
    audience?: number
    productID?: string
    imageURL?: string
    type: 'product' | 'platform'
    url?: string
    urlLabel?: string
    statistics?: {
        prospects: number
        clicks: number
        activations: number
        redemptions: number
    }
}

export type OfferActivation = {
    id: string
    offerID: string
    status: 'Active' | 'Consumed' | 'Expired'
    activatedDate: string
    consumedDate?: string
    contactName: string
    offer: Offer
    type: 'product' | 'platform'
}

export type Subscription = {
    id: string
    status: string
    plan: string
    planName: string
    featuresEnabled: string[]
    start: string
    frequency: string
    nextCharge: string
    maxLocations: number
}

export type Holding = {
    id: string
    name?: string
    product: string
    productId: string
    currencyMultiplier: number
    currencySymbol?: string
    asset: string
    balance: number
    paymentReference?: string
    planTotal?: number
    saleValue?: number
    initialDeposit?: number
    planVariation?: number
    targetDate?: string
    closed: boolean
    closureReason?: string
    closureDate?: string
    cardNumber?: string
    cardColor?: string
    cardBackground?: string
    mandates: {
        id: string
    }[]
    rewardsDetails?: {
        max: number
        activities: Activity[]
        goals: Goal[]
    }
    bookingURL?: string
    bookingPhone?: string
    offers?: Offer[]
}

export type Account = {
    id: string
    accountName: string
    accountTradingName?: string
    accountType?: string
    entityType?: string
    reviewed: boolean
    role: string
    employees?: number
    signatory: boolean
    currentHealthFund?: string
    currentHealthType?: string
    businessNumber?: string
    businessWebsite?: string
    businessIndustry?: string
    bookingURL?: string
    bookingPhone?: string
    businessAddress?: Address
    subscriptions?: Subscription[]
    stores?: Store[]
    holdings: Holding[]
    foundationDate?: string
    industryName?: string
}

// queries
export type Contact = {
    id: string
    title?: string
    firstName: string
    middleNames?: string
    lastName: string
    lastNameSecond?: string
    suffix?: string
    email: string
    emailSecondary?: string
    emailVerified: boolean
    dob?: string
    gender?: string
    civilStatus?: string
    dependents?: number
    phone?: string
    phoneSecondary?: string
    phoneVerified: boolean
    multifactorEnabled: boolean
    tsAndCs: boolean
    address?: Address
    addressPresent?: Address
    emergencyName?: string
    emergencyPhone?: string
    emergencyRelationship?: string
    accounts: Account[]
    offers: Offer[]
}
export const getUserContact = async () => plutoQuery<Contact>`
    contact {
        id
        title
        firstName
        middleNames
        lastName
        lastNameSecond
        suffix
        email
        emailSecondary
        emailVerified
        dob
        gender
        civilStatus
        dependents
        phone
        phoneSecondary
        phoneVerified
        multifactorEnabled
        tsAndCs
        address {
            unit
            number
            streetName
            suburb
            city
            postCode
            state
            country
        }
        addressPresent {
            unit
            number
            streetName
            suburb
            city
            postCode
            state
            country
        }
        emergencyName
        emergencyPhone
        emergencyRelationship
        accounts {
            id
            accountName
            accountTradingName
            accountType
            entityType
            reviewed
            role
            employees
            signatory
            currentHealthFund
            currentHealthType
            businessNumber
            businessWebsite
            businessIndustry
            bookingURL
            bookingPhone
            unreadClaims
            dealCount
            unreadOffers
            foundationDate
            industryName
            businessAddress {
                unit
                number
                streetName
                suburb
                city
                postCode
                state
                country
            }
            subscriptions {
                id
                status
                plan
                planName
                featuresEnabled
                start
                frequency
                nextCharge
                maxLocations
            }
            stores {
                id
                name
                bookingURL
                phone
                address {
                    unit
                    number
                    streetName
                    suburb
                    city
                    postCode
                    state
                    country
                }
            }
            holdings {
                id
                name
                product
                productId
                currencyMultiplier
                currencySymbol
                asset
                balance
                paymentReference
                planTotal
                saleValue
                initialDeposit
                planVariation
                targetDate
                closed
                closureReason
                closureDate
                cardNumber
                cardColor
                cardBackground
                mandates {
                    id
                }
                rewardsDetails {
                    max
                    activities {
                        id
                        type
                        title
                        message
                        receive
                        imgURL
                        externalLink
                        value
                        status
                        basis
                        priority
                    }
                    goals {
                        id
                        title
                        value
                    }
                }
                bookingURL
                bookingPhone
                offers {
                    id
                    created
                    title
                    description
                    expiryDate
                    activationLastDate
                    activationLastDatePeriod
                    imageURL
                    stores {
                        name
                        id
                    }
                    maxActivations
                    audience
                    type
                    url
                    urlLabel
                }
            }
        }
        unreadMessages
        offers {
            id
            created
            title
            description
            expiryDate
            activationLastDate
            activationLastDatePeriod
            imageURL
            stores {
                name
                id
            }
            maxActivations
            audience
            type
            url
            urlLabel
        }
    }
`

type GetOfferActivationsResponse = {
    accounts: [{ offerActivations: PaginatedResponse<OfferActivation> }]
}
export const getOfferActivations = async (accountID: string, pagination: Pagination, statuses: string[], sort: string) => {
    const res = await plutoQuery<GetOfferActivationsResponse>`
        contact {
            accounts(id:"${accountID}") {
                offerActivations(pagination: ${pagination}, status: ${statuses}, sort: ${sort}) {
                    set {
                        id
                        status
                        activatedDate
                        consumedDate
                        contactName
                        offer {
                            id
                            title
                            description
                            activationLastDate
                            activationLastDatePeriod
                            stores {
                                id
                                name
                                bookingURL
                            }
                            bookingURL
                        }
                        type
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.offerActivations ?? null
}

type GetOfferActivationResponse = {
    accounts: [{ offerActivation: OfferActivation }]
}
export const getOfferActivation = async (accountID: string, activationID: string) => {
    const res = await plutoQuery<GetOfferActivationResponse>`
        contact {
            accounts(id:"${accountID}") {
                offerActivation(id:"${activationID}") {
                    id
                    status
                    activatedDate
                    consumedDate
                    contactName
                    offer {
                        title
                        description
                        activationLastDate
                        activationLastDatePeriod
                    }
                    type
                }
            }
        }
    `
    return res?.accounts[0]?.offerActivation ?? null
}

export type AccountContact = {
    id: string
    email: string
    phone?: string
    role: string
    firstName: string
    lastName: string
    storeID?: string
    relationship?: string
    signatory: boolean
}
type GetAccountContactsResponse = { accounts: [{ contacts: AccountContact[] }] }
export const getAccountContacts = async (accountID: string) => {
    const res = await plutoQuery<GetAccountContactsResponse>`
        contact {
            accounts(id:"${accountID}") {
                contacts {
                    id
                    email
                    phone
                    role
                    firstName
                    lastName
                    storeID
                    relationship
                    signatory
                }
            }
        }
    `
    return res?.accounts[0]?.contacts ?? null
}

type Transaction = {
    id: string
    type: string
    subtype?: string
    amount: number
    balance: number
    date: string
    time: number
    bankDetails?: {
        bsb?: string
        acc?: string
    }
    request?: string
    otherAccount?: string
    claimName?: string
    goalName?: string
}
type GetHoldingTransactionsResponse = {
    accounts: [{ holdings: [{ transactions: PaginatedResponse<Transaction> }] }]
}
export const getHoldingTransactions = async (accountID: string, holdingID: string, pagination: Pagination) => {
    const res = await plutoQuery<GetHoldingTransactionsResponse>`
        contact {
            accounts(id:"${accountID}") {
                holdings(id:"${holdingID}") {
                    transactions(pagination:${pagination}) {
                        set {
                            id
                            type
                            subtype
                            amount
                            balance
                            date
                            time
                            bankDetails {
                                bsb
                                acc
                            }
                            requestID
                            otherAccount
                            claimName
                            goalName
                        }
                        pagination {
                            nextPage
                            prevPage
                            total
                        }
                    }
                }
            }
        }`
    return res?.accounts[0]?.holdings[0]?.transactions ?? null
}

export type Message = {
    id: string
    title: string
    date: string
    expiry?: string
    message: string
    sender: string
    read: boolean
    attachments: {
        name: string
        url: string
        expiry?: string
    }[]
    status?: {
        text: string
        color: string
    }
}
type GetUserMessageResponse = {
    message: Omit<Message, 'status'>
}
export const getUserMessage = async (messageID: string) => {
    const res = await plutoQuery<GetUserMessageResponse>`
        contact {
            message(id:"${messageID}") {
                id
                title
                date
                expiry
                message
                sender
                read
                attachments {
                    name
                    url
                    expiry
                }
            }
        }
    `
    return res?.message ?? null
}

type GetUserMessagesResponse = {
    messages: PaginatedResponse<Omit<Message, 'status'>>
}
export const getUserMessages = async (pagination: Pagination, search: string) => {
    const res = await plutoQuery<GetUserMessagesResponse>`
        contact {
            messages(pagination: ${pagination}, search: "${search}") {
                set {
                    id
                    title
                    date
                    expiry
                    message
                    sender
                    read
                    attachments {
                        name
                        url
                        expiry
                    }
                }
                pagination {
                    nextPage
                    prevPage
                    total
                    pageNavigation {
                        page
                        items
                        cursor
                    }
                }
            }
        }
    `
    return res?.messages ?? null
}

type GetUserUnreadMessagesResponse = { unreadMessages: number }
export const getUserUnreadMessages = async () => {
    const res = await plutoQueryQuiet<GetUserUnreadMessagesResponse>`
        contact {
            unreadMessages
        }
    `
    return res?.unreadMessages ?? 0
}

export const getActionContract = async (actionID: string) => plutoQuery<string>`actionContract(actionID:"${actionID}")`

type DocumentFile = {
    name: string
    url?: string
    size: number
    id: string
}
type Document = {
    id: string
    size: number
    date: string
    classification?: string
    files: DocumentFile[]
}
type GetUserDocumentsResponse = PaginatedResponse<Omit<Document, 'files'>>
export const getUserDocuments = async (pagination: Pagination) =>
    plutoQuery<GetUserDocumentsResponse>`
        documents(pagination: ${pagination}) {
            set {
                id
                size
                date
                classification
            }
            pagination {
                nextPage
                prevPage
                total
                pageNavigation {
                    page
                    items
                    cursor
                }
            }
        }
    `

export const getDocumentFiles = async (documentID: string) => plutoQuery<DocumentFile[]>`
        files(documentID:"${documentID}") {
            id
            name
            size
            url
        }
`

type Product = {
    id: string
    name: string
    businessName: string
    checkinOn: boolean
}
export const getProduct = async (productID: string) => plutoQuery<Product>`
    product(id:"${productID}") {
        id
        name
        businessName
        checkinOn
    }
`

export const getProductByAsset = async (asset: string) => plutoQuery<Product>`
    productByAsset(asset:"${asset}") {
        id
        name
        businessName
        checkinOn
    }
`

type Statement = {
    id: string
    name: string
    url: string
}
type GetUserStatementsResponse = {
    accounts: [{ statements: PaginatedResponse<Statement> }]
}
export const getUserStatements = async (accountID: string, pagination: Pagination) => {
    const res = await plutoQuery<GetUserStatementsResponse>`
        contact {
            accounts(id:"${accountID}") {
                statements(pagination: ${pagination}) {
                    set {
                        id
                        name
                        url
                    }
                    pagination {
                        nextPage
                        prevPage
                        total
                        pageNavigation {
                            page
                            items
                            cursor
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.statements ?? null
}

export type FinStat = {
    id: string
    year: number
    taxCollected: boolean
    statURL: string
    taxURL: string
}
type GetUserFinancialStatementsResponse = {
    accounts: [{ financialStatements: FinStat[] }]
}
export const getUserFinancialStatements = async (accountID: string) => {
    const res = await plutoQuery<GetUserFinancialStatementsResponse>`
        contact {
            accounts(id:"${accountID}") {
                financialStatements {
                    id
                    year
                    taxCollected
                    statURL
                    taxURL
                }
            }
        }
    `

    return res?.accounts[0].financialStatements
}

export type BusinessDocument = {
    id: string
    classification: string
    url: string
}
type GetUserBusinessDocumentsResposne = {
    accounts: [{ businessDocuments: BusinessDocument[] }]
}
export const getUserBusinessDocuments = async (accountID: string, classifications: string[]) => {
    const res = await plutoQuery<GetUserBusinessDocumentsResposne>`
        contact {
            accounts(id:"${accountID}") {
                businessDocuments(classifications:${classifications.map((c) => `"${c}"`)}) {
                    id
                    classification
                    url
                }
            }
        }
    `

    return res?.accounts[0].businessDocuments
}

export type Entity = { id: string; name: string }
type GetUserPreviousLenderResponse = {
    accounts: [{ prevLender: Entity | null }]
}
export const getUserPreviousLender = async (accountID: string) => {
    const res = await plutoQuery<GetUserPreviousLenderResponse>`
        contact {
            accounts(id:"${accountID}") {
                prevLender {
                    id
                    name
                }
            }
        }
    `

    return res?.accounts[0]?.prevLender ?? null
}

// mutations
type SubmitSupportTicketInput = {
    contactID?: string
    accountID?: string
    holdingID?: string
    description?: string
}
export const submitSupportTicket = async (input: SubmitSupportTicketInput) => plutoMutation`
    submitSupportTicket(input: ${input}) ${mutationResponseString}
`

type CreateActivityInput = {
    accountID: string
    holdingID: string
    to: string
    from: string
    type: string
}
export const createActivity = async (input: CreateActivityInput) => plutoMutation`
    createActivity(input: ${input}) ${mutationResponseString}
`

type UpdateUserInput = {
    title?: string
    firstName?: string
    middleNames?: string
    lastName?: string
    lastNameSecond?: string
    suffix?: string
    dob?: string
    gender?: string
    civilStatus?: string
    dependents?: number
    email?: string
    emailSecondary?: string
    phone?: string
    phoneSecondary?: string
    emergencyName?: string
    emergencyPhone?: string
    emergencyRelationship?: string
    address?: Address
    addressPresent?: Address
}
export const updateUser = async (input: UpdateUserInput) => plutoMutation`
    updateUser(input: ${input}) ${mutationResponseString}
`

type UpdateAccountInput = {
    accountID: string
    currentHealthFund?: string
    currentHealthType?: string
}
export const updateAccount = async (input: UpdateAccountInput) => plutoMutation`
    updateAccount(input: ${input}) ${mutationResponseString}
`

type AddUserDeviceResponse = { success: boolean; message: string; deviceID?: string }
export const addUserDevice = async (notificationToken: string) => plutoMutation<AddUserDeviceResponse>`
    addUserDevice(input: ${{ token: notificationToken }}) { success message deviceID }
`

type SignContractInput = {
    actionID: string
    contactID: string
    accountID: string
    device: string
}
export const signContract = async (input: SignContractInput, file: File) => {
    const query = `mutation($file: Upload!) {
        signContract(input: ${stringify(input)}, file: $file) ${mutationResponseString}
    }`
    return plutoFormMutation(query, { file })
}

export const setMessageRead = async (actionID: string, read: boolean) =>
    plutoMutation`readMessage(input: ${{ actionID, read }}) ${mutationResponseString}`

type UploadDocumentInput = {
    contactID: string
    accountID: string
    fateID?: string
    actionID?: string
    dealID?: string
    classification: string
    classificationOther?: string
}
export const uploadDocument = async (input: UploadDocumentInput, files: File[]) => {
    const query = `mutation($files: [Upload!]!) {
        uploadDocument(input: ${stringify(input)}, files: $files) ${mutationResponseString}
    }`
    return plutoFormMutation(query, { files })
}

type CreateUserHoldingInput = {
    product: string
    account: string
    store?: string | null
    referrer?: string
    nickname?: string
    purchase?: number
    target?: string
    dynamicInputs?: Record<string, string | Record<string, string>>
}
export const createUserHolding = async (input: CreateUserHoldingInput, dynamicFiles: File[]) =>
    plutoFormMutation(
        `
            mutation($dynamicFiles: [Upload!]!) {
                addProduct(input: ${stringify(input)}, dynamicFiles: $dynamicFiles) ${mutationResponseString}
            }
        `,
        { dynamicFiles }
    )

export const recordUserLogin = async () => plutoMutation`recordLogin ${mutationResponseString}`

export const updateBankAccountStatus = async (bankAccountID: string, status: boolean) =>
    plutoMutation`updateBankAccount(input: ${{ id: bankAccountID, status }}) ${mutationResponseString}`

type UploadBankStatementInput = {
    accountID: string
    bankAccountID: string
    start: string
    end: string
}
export const uploadBankStatement = async (input: UploadBankStatementInput, file: File) =>
    plutoFormMutation(
        `
            mutation($file: Upload!) {
                uploadBankStatement(input: ${stringify(input)}, file: $file) ${mutationResponseString}
            }
        `,
        { file }
    )

type UploadTaxReturnInput = {
    accountID: string
    finStatID: string
}
export const uploadTaxReturn = async (input: UploadTaxReturnInput, file: File) =>
    plutoFormMutation(
        `
            mutation($file: Upload!) {
                uploadTaxReturn(input: ${stringify(input)}, file: $file) ${mutationResponseString}
            }
        `,
        { file }
    )

type UploadFinStatInput = {
    accountID: string
    year: string
}
export const uploadFinStat = async (input: UploadFinStatInput, stat: File, tax?: File) =>
    plutoFormMutation(
        `
            mutation($stat: Upload!, $tax: Upload) {
                uploadFinStat(input: ${stringify(input)}, stat: $stat, tax: $tax) ${mutationResponseString}
            }
        `,
        { stat, ...(tax ? { tax } : {}) }
    )

type UploadBusinessDocumentInput = {
    accountID: string
    classification: string
}
export const uploadBusinessDocument = async (input: UploadBusinessDocumentInput, file: File) =>
    plutoFormMutation(
        `
            mutation($file: Upload!) {
                uploadBusinessDocument(input: ${stringify(input)}, file: $file) ${mutationResponseString}
            }
        `,
        { file }
    )

type AddReferrerInput = {
    accountID: string
    referrerID: string
}
export const addReferrer = async (input: AddReferrerInput) => plutoMutation`addReferrer(input: ${input}) ${mutationResponseString}`
