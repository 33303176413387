/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import Validator from 'validator'

export type TextValidation = 'anything' | 'numeric' | 'alpha' | 'email' | 'password' | 'integer' | 'float' | 'url'
export type DateValidation = 'anything' | 'before' | 'after'

export type InputType = 'text' | 'dropdown' | 'date' | 'phone' | 'textarea' | 'record' | 'address' | 'file' | 'signature'

export type Operator = 'EQUAL' | 'NOT_EQUAL' | 'GREATER' | 'GREATER_EQUAL' | 'LESS' | 'LESS_EQUAL'

export type FormInput = {
    type: InputType
    label: string
    name: string
    field: string
    fieldPrefix?: string
    placeholder?: string
    guide?: string
    dateValidation?: DateValidation
    textValidation?: TextValidation[]
    textValidationDependsOn?: {
        field: string
        operator: Operator
    }
    required: boolean
    options: { text: string; value: string }[]
    prefix?: string
    currencyPrefix: boolean
    suffix?: string
    dependsOn?: {
        field: string
        operator: Operator
        value: string
    }
}

export type FormPage = {
    id: string
    title: string
    index: number
    module?: string
    extend?: string
    field?: string
    inputs: FormInput[]
}

const mapTextValidation = (validator: typeof Validator, validation: TextValidation): ((v: string) => boolean) => {
    switch (validation) {
        case 'anything':
            return () => true
        case 'alpha':
            return validator.isAlpha
        case 'numeric':
            return validator.isNumeric
        case 'integer':
            return validator.isInt
        case 'float':
            return validator.isFloat
        case 'email':
            return validator.isEmail
        case 'url':
            return validator.isURL
        case 'password':
            return validator.isStrongPassword
        default:
            return () => false
    }
}

const createTextValidatorInit =
    (validator: typeof Validator, validations: TextValidation[]): ((v: string) => boolean) =>
    (v) =>
        validations.some((val) => mapTextValidation(validator, val)(v))

export const createTextValidator = (validator: typeof Validator, validations: TextValidation[], required = false): ((v: string) => boolean) =>
    required ? createTextValidatorInit(validator, validations) : (v) => createTextValidatorInit(validator, validations)(v) || validator.isEmpty(v)

const mapDateValidation = (validator: typeof Validator, validation: DateValidation): ((v: string) => boolean) => {
    switch (validation) {
        case 'anything':
            return () => true
        case 'after':
            return validator.isAfter
        case 'before':
            return validator.isBefore
        default:
            return () => false
    }
}

const createDateValidatorInit =
    (validator: typeof Validator, validation: DateValidation): ((v: string) => boolean) =>
    (v) =>
        mapDateValidation(validator, validation)(v)

export const createDateValidator = (validator: typeof Validator, validation: DateValidation, required = false): ((v: string) => boolean) =>
    required ? createDateValidatorInit(validator, validation) : (v) => createDateValidatorInit(validator, validation)(v) || validator.isEmpty(v)

export const formPageGQLString = `{ id title index module extend field inputs { type label name field fieldPrefix placeholder guide dateValidation textValidation textValidationDependsOn { field operator } required options { text value } prefix currencyPrefix suffix dependsOn { field operator value } } }`

export const separateDynamicFiles = (dynamicInput: Record<string, any>, files: File[] = []): [Record<string, any>, File[]] => {
    const replacedDynamicInput = { ...dynamicInput }
    const recordFields = Object.keys(replacedDynamicInput).filter((k) => k.split('__').length === 3 && !k.startsWith('sign__'))
    recordFields.forEach((rf) => {
        const [replaceRecordDynamicInput] = separateDynamicFiles(replacedDynamicInput[rf], files)
        replacedDynamicInput[rf] = replaceRecordDynamicInput
    })

    const fileFields = Object.keys(replacedDynamicInput).filter((k) => replacedDynamicInput[k] instanceof File)
    const replacedFileFieldsDynamicInput = fileFields.reduce((t, c, i) => ({ ...t, [c]: i + files.length }), replacedDynamicInput)
    files.push(...fileFields.reduce((t, c) => [...t, replacedDynamicInput[c as keyof typeof replacedDynamicInput] as File], [] as File[]))

    return [replacedFileFieldsDynamicInput, files]
}
