/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { useEffect, useState } from 'react'
import { UiNode, UiNodeGroupEnum } from '@ory/client'
import { Popup } from 'semantic-ui-react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useFunctionState } from 'vspry-hooks'
import { Button, FlexBox, Text } from 'vspry-style-components'

import { useAuth } from 'context/authContext'
import { translateContextless, useLocale } from 'context/localeContext'
import { useAppConfig } from 'context/appConfigContext'

import { isTenant, Type } from 'services/auth/interface'
import KittedTile from 'components/KittedTile'
import theme from 'styles/theme'
import Node from './Node'
import KratosAuthProvider, { KratosFlow } from '../provider'
import { translateAuthCode } from './util'
import { isInputNode } from './Input'

const methods = {
    password: translateContextless('inputs.password'),
    webauthn: translateContextless('inputs.webauthn'),
}

export default function Flow({ type, auth }: { type: Type; auth: KratosAuthProvider }) {
    const { appConfig } = useAppConfig()
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { translate, translateMultivalue } = useLocale()
    const { logout, identity } = useAuth()
    const [{ flow, method, fetching }, setState] = useFunctionState<{ flow?: KratosFlow; method?: keyof typeof methods; fetching: boolean }>({
        fetching: false,
    })
    const [groups, setGroups] = useState<Record<UiNodeGroupEnum, UiNode[]> | undefined>(undefined)

    useEffect(() => {
        const tenant = searchParams.get('tenant')
        if (tenant && isTenant(tenant)) auth.setTenant(tenant)

        const redirect = searchParams.get('redirect') ?? undefined
        const flowID = searchParams.get('flow') ?? undefined
        auth.getFlow(type, flowID, redirect ? decodeURIComponent(redirect) : undefined).then((d) => setState({ flow: d }))
    }, [location])

    useEffect(() => {
        setGroups(
            flow?.ui.nodes.reduce(
                (t, c) => ({
                    ...t,
                    ...(type === 'registration' && !(c.group === 'password' || c.group === 'default')
                        ? {}
                        : { [c.group]: [...(t[c.group] ?? []), c] }),
                }),
                {} as Record<UiNodeGroupEnum, UiNode[]>
            )
        )
    }, [flow])

    useEffect(() => {
        const e = flow?.ui.nodes.find((n) => n.messages.length > 0)
        if (groups && e && !method) setState({ method: Object.keys(groups).find((k) => e.group === k) as keyof typeof methods })
    }, [groups])

    const renderGroup = (group: UiNode[]) => (
        <FlexBox $column $fitted gap='small'>
            {group.map((n) => (
                <Node node={n} signin={type === 'login'} />
            ))}
        </FlexBox>
    )

    if (fetching || !flow || !groups) return <p>{translate('common.fetching')}</p>

    if (type === 'settings')
        return (
            <FlexBox $column gap='medium'>
                {'password' in groups && (
                    <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                        <KittedTile
                            title={translate('pages.profile.loginMethods.password.title')}
                            description={translate('pages.profile.loginMethods.password.description')}
                        >
                            {renderGroup(groups['password'])}
                            {renderGroup(groups['default'])}
                        </KittedTile>
                    </form>
                )}
                {'webauthn' in groups && (
                    <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                        <KittedTile
                            title={translate('pages.profile.loginMethods.passkey.title')}
                            description={translate('pages.profile.loginMethods.passkey.description')}
                        >
                            {renderGroup(
                                groups['webauthn'].filter(
                                    (e, _, a) =>
                                        !(
                                            a.some((o) => isInputNode(o) && o.attributes.name === 'webauthn_remove') &&
                                            isInputNode(e) &&
                                            e.attributes.name === 'webauthn_register_trigger'
                                        )
                                )
                            )}
                            {renderGroup(groups['default'])}
                        </KittedTile>
                    </form>
                )}
                <Text
                    size='small'
                    $bold
                    onClick={() => {
                        navigate('/profile')
                    }}
                    margin='no'
                >
                    {translate('buttons.back')}
                </Text>
            </FlexBox>
        )

    return (
        <FlexBox $column $align gap='medium' width='75%'>
            {flow.ui.messages &&
                flow.ui.messages &&
                flow.ui.messages.map((m) => (
                    <Text size='xSmall' margin='no'>
                        {translateAuthCode(translateMultivalue, m)}
                    </Text>
                ))}
            <form action={flow.ui.action} method={flow.ui.method} style={{ width: '100%' }}>
                <FlexBox $column $fitted gap='small'>
                    {Object.keys(groups).length > 2 && renderGroup(groups['default'])}
                    {(type === 'recovery' || Object.keys(groups).length <= 2 || method) &&
                        Object.keys(groups)
                            .filter((k) => (type !== 'recovery' && Object.keys(groups).length > 2 ? k === method : true))
                            .map((k) => renderGroup(groups[k as keyof typeof groups]))}
                    {flow?.state === 'choose_method' && !method && Object.keys(groups).length > 2 && (
                        <FlexBox $column $fitted gap='small'>
                            {Object.keys(groups)
                                .filter((k) => k !== 'default')
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((k, i) => (
                                    <Popup
                                        trigger={
                                            <Button
                                                id={`auth-flow-method-${k}`}
                                                onClick={k !== 'webauthn' ? () => setState({ method: k as keyof typeof methods }) : undefined}
                                                customColor={i > 0 ? `${theme.disabledButtonBG}40` : undefined}
                                                textColor={i > 0 ? theme.text : ''}
                                                name='method'
                                                type={k === 'webauthn' ? 'submit' : undefined}
                                                value={k}
                                            >
                                                {translateMultivalue('authFlows', type)} {translate('common.with')}{' '}
                                                {methods[k as keyof typeof methods]}
                                            </Button>
                                        }
                                        on={['hover']}
                                        position='top center'
                                    >
                                        {translateMultivalue('authMethods', k as any)}
                                    </Popup>
                                ))}
                        </FlexBox>
                    )}
                </FlexBox>
            </form>
            {!(type === 'login' && !appConfig.consumers_enabled && appConfig.merchants_enabled) && (
                <Text
                    size='small'
                    $bold
                    onClick={() => {
                        if (method && Object.keys(groups).length > 2) {
                            setState({ method: undefined })
                            return
                        }
                        navigate('/')
                        if (identity) logout()
                    }}
                    margin='no'
                >
                    {translate('buttons.back')}
                </Text>
            )}
        </FlexBox>
    )
}
